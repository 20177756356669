import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/components/index.vue'),
    meta:{isAuth:false}
  },
  {
    path: '/indexp',
    name: 'indexp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/components/indexp.vue'),
    meta:{isAuth:false}
  },
  {
    path: '/Login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue'),
    meta:{isAuth:false}
  },
  {
    path: '/Home',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
    meta:{isAuth:true}
  }
]

const router = new VueRouter({
  mode: 'history',

  routes
});
router.beforeEach((to, from, next)=>{

  if (to.meta.isAuth){
    if (window.sessionStorage.getItem("dr")){
      next();
    }else {
      next("/login");
    }
  }else {
    next();
  }


});
export default router
