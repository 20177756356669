// 导入安装的axios
import axios from 'axios'

// 创建axios请求实例,并暴
const myaxios = axios.create({
    baseURL: 'http://zss.9jtx.cn:8080/zs',
    timeout: 5000,
    headers: { 'X-Custom-Header': 'foobar' }
});

//暴露接口
export default myaxios;
