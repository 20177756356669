<template>
  <div id="app">

    <router-view/>
  </div>
</template>
<script>
export default {
// eslint-disable-next-line vue/multi-word-component-names
  name: "App",
  data() {
    return {
    };
  },
  mounted() {
    this.apc();
  },
  methods: {
    //判断是手机还是电脑
    apc(){
      if (this.$router.history._startLocation==="/login"){
        this.$router.push(`/Login`)
      }else if (this.$router.history._startLocation==="/Home"){
        this.$router.push(`/Home`)
      }else {
        if (this._isMobile()){
          this.$router.push(`/`)
        }else {
          this.$router.push(`/indexp`)
        }
      }
    },
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
